.radio-switch {
  display: grid;
  place-items: start;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(30px, 40px);
  margin-top: -0.75em;
}

.video, .audio, .image, .text {
  font-family: 'EB Garamond', serif;
  display: grid;
  place-items: start;
  grid-template-columns: repeat(2, 1fr);
  margin-right: 1em;
  column-gap: 0.5em;
  color: white;
}

h2 {
  margin-top: 0.8em;
  margin-left: -0.18em;
}

.text h2 {
  margin-left: -0.09em;
}

.image h2 {
  margin-left: -0.2em;
}

.switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 40px;
  height: 22px;
  background: #878694;
  border-radius: 100px;
  border: 3px solid #a8546c;
  position: relative;
  transition: background-color .2s;
}

.switch-label .switch-button {
  position: absolute;
  top: 0;
  left: 2px;
  width: 23px;
  height: 22px;
  border-radius: 30px;
  transition: 0.2s;
  background: rgb(73, 72, 72);
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switch-checkbox {
  opacity: 0;
}

.switch-checkbox:checked + .switch-label .switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
  background: #4f3e7f;
}

.switch-label:active .switch-button {
  width: 30px;
}

.switch-checkbox:checked + .switch-label {
  background: #ddd5f1;
}
